<template>
  <div class="ra-basic ra-basic-text">
    <p>
      Some of my pet projects:
    </p>
    <p>
      <b>rip_action builder</b> - loot filter and actions builder for community of the MMO/MUD game BatMUD
      <br />

      <RaLink
        href="https://rip-action.ralmaz.pro"
        follow
        styled
        no-wrap
      >
        <b>https://rip-action.ralmaz.pro</b>
      </RaLink>
    </p>
  </div>
</template>

<script>
import RaLink from '@/components/elements/RaLink.vue'

export default {
  name: 'Pets',

  metaInfo: {
    title: 'Pets',
    titleTemplate: '%s | Roman Almazov'
  },

  components: {
    RaLink
  },

  methods: {
    openLink(link) {
      window.open(link, '_blank').focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ra-code-link {
    background-color: #272822;
    color: #f8f8f2;
    padding: 3px;
    cursor: pointer;
  }
</style>
